<template>
  <b-overlay :show="dataLoading" spinner-variant="primary" variants="white" opacity="0.6">
    <div class="mt-2 text-left w-100">
      <vs-row class="mb-4">
        <vs-switch v-model="settings.analyticsEnabled" class="inline-flex">
          <template v-slot:on>
            {{ $t('search.settings.analyticsEnabled') }}
          </template>
          <template v-slot:off>
            {{ $t('search.settings.analyticsDisabled') }}
          </template>
        </vs-switch>
      </vs-row>

      <vs-row class="mb-1 w-100">
        <vs-tooltip class="w-100" :text="$t('search.settings.analyticsMeasurementIdTooltip')">
          <vs-input
            v-model="settings.analyticsMeasurementId"
            class="w-100"
            :label-placeholder="$t('search.settings.analyticsMeasurementId')"
          />
        </vs-tooltip>
      </vs-row>

      <vs-row class="w-100">
        <vs-tooltip class="w-100" :text="$t('search.settings.analyticsUseSiteSearchTooltip')">
          <vs-select
            v-model="settings.analyticsUseSiteSearch"
            class="w-100"
            :label="$t('search.settings.analyticsUseSiteSearchHeader')"
          >
            <vs-select-item key="false" value="0" :text="$t('search.settings.analyticsUseCustomEvents')" />
            <vs-select-item key="true" value="1" :text="$t('search.settings.analyticsUseSiteSearch')" />
          </vs-select>
        </vs-tooltip>
      </vs-row>
    </div>
  </b-overlay>
</template>
<script>
export default {
  name: 'GoogleAnalyticsSearch',
  props: {
    settings: {},
    dataLoading: {},
  },
  watch: {
    dataLoading() {
      return this.dataLoading
    },
  },
}
</script>
