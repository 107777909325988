<template>
  <b-overlay :show="dataLoading" spinner-variant="primary" variants="white" opacity="0.6">
    <vs-row>
      <div class="text-center w-100">
        <vs-alert active="true" class="mt-3 mb-3 p-2">
          <h2 class="mb-5 ">
            {{ $t('search.settings.functionNotAvailable') }}
          </h2>
          <p>
            {{ $t('search.settings.functionNotAvailableContact') }}
            <a href="mailto:sales@quarticon.com">
              <strong>sales@quarticon.com</strong>
            </a>
            {{ $t('search.settings.functionNotAvailableContactOr') }}
            <a target="_blank" href="https://meetings.hubspot.com/adrian-bera/spotkanie">
              <strong>
                {{ $t('search.settings.functionNotAvailableContactScheduleMeeting') }}
              </strong>
            </a>.
          </p>
        </vs-alert>
      </div>
    </vs-row>
  </b-overlay>
</template>
<script>
export default {
  name: 'FeatureIsNotAvailableMessage',
  props: {
    dataLoading: {},
  },
  watch: {
    dataLoading() {
      return this.dataLoading
    },
  },
}
</script>
