<template>
  <b-overlay :show="dataLoading" spinner-variant="primary" variants="white" opacity="0.6">
    <div class="text-left mt-2">
      <vs-row class="mb-4">
        <vs-switch v-model="settings.enabled" class="inline-flex">
          <template v-slot:on>
            {{ $t('search.settings.enabled_on') }}
          </template>
          <template v-slot:off>
            {{ $t('search.settings.enabled_off') }}
          </template>
        </vs-switch>
      </vs-row>

      <vs-row vs-type="flex" vs-justify="left" class="mb-1">
        <vs-checkbox v-model="settings.showUnavailable">
          {{ $t('search.settings.showUnavailable') }}
        </vs-checkbox>
      </vs-row>

      <vs-row vs-type="flex" vs-justify="left" class="mb-2">
        <vs-checkbox v-model="settings.showOnlyWithImages">
          {{ $t('search.settings.showOnlyWithImages') }}
        </vs-checkbox>
      </vs-row>

      <vs-row vs-type="flex" vs-justify="left" class="mb-2">
        <vs-checkbox v-model="settings.showVariantsOfProduct">
          {{ $t('search.settings.showVariantsOfProduct') }}
        </vs-checkbox>
      </vs-row>

      <vs-row vs-type="flex" vs-justify="left" class="mb-2">
        <vs-checkbox v-model="settings.showAllVariantsOfProducts">
          {{ $t('search.settings.showAllVariantsOfProducts') }}
        </vs-checkbox>
      </vs-row>

      <vs-row vs-type="flex" vs-justify="left">
        <label>
          {{ $t('search.settings.itemsPerPage') }}
        </label>
      </vs-row>
      <vs-row vs-type="flex" vs-justify="left">
        <vs-input-number v-model="settings.itemsPerPage" :step="1" :min="1" :max="100" />
      </vs-row>
    </div>
  </b-overlay>
</template>
<script>

export default {
  name: 'GeneralTab',
  props: {
    settings: {},
    dataLoading: {},
  },
  watch: {
    dataLoading() {
      return this.dataLoading
    },
  },
}
</script>
