<template>
  <div ref="container">
    <b-card :title="$t('search.settings.header')">
      <vs-alert v-if="success !== null" class="mb-2" color="success" :title="$t('alert.success')" active="true">
        {{ success }}
      </vs-alert>

      <vs-alert v-if="error != null" class="mb-2" color="danger" :title="$t('alert.error')" active="true">
        {{ error }}
      </vs-alert>

      <vs-alert v-if="unsaved" class="mb-2" color="warning" :title="$t('alert.warning')" active="true">
        {{ $t('search.settings.unsaved') }}
      </vs-alert>

      <vs-tabs>
        <vs-tab :label="$t('search.settings.generalTabTitle')">
          <GeneralTab :settings="settings" :data-loading="dataLoading" />
        </vs-tab>
        <vs-tab :label="$t('search.settings.gaTabTitle')">
          <GoogleAnalyticsSearch :settings="settings" :data-loading="dataLoading" />
        </vs-tab>
        <vs-tab :label="$t('search.settings.popularQueries.title')">
          <PopularQueriesSettings
            v-if="hasFeature('ss_popularQueries')"
            :popular-queries-duration-options="popularQueriesDurationOptions"
            :settings="settings"
          />
          <FeatureIsNotAvailableMessage v-else :data-loading="dataLoading" />
        </vs-tab>
        <vs-tab :label="$t('search.settings.recentQueries.title')">
          <RecentQueriesSettings v-if="hasFeature('ss_userRecentQueries')" :settings="settings" />
          <FeatureIsNotAvailableMessage v-else :data-loading="dataLoading" />
        </vs-tab>
      </vs-tabs>

      <vs-row class="mt-3">
        <b-button variant="primary" @click="updateTemplate">
          {{ $t('search.settings.save') }}
        </b-button>
      </vs-row>

    </b-card>
  </div>
</template>

<script>
import axios from '@/apiRequest'
import store from '@/store'
import GeneralTab from '@/views/components/searchSettings/GeneralTab.vue'
import GoogleAnalyticsSearch from '@/views/components/searchSettings/GoogleAnalyticsSearch.vue'
import PopularQueriesSettings from '@/views/components/searchSettings/PopularQueriesSettings.vue'
import RecentQueriesSettings from '@/views/components/searchSettings/RecentQueriesSettings.vue'
import FeatureIsNotAvailableMessage from '@/views/components/searchSettings/FeatureIsNotAvailableMessage.vue'

export default {
  components: {
    RecentQueriesSettings,
    PopularQueriesSettings,
    GoogleAnalyticsSearch,
    GeneralTab,
    FeatureIsNotAvailableMessage,
  },
  data() {
    return {
      loaded: false,
      originalData: null,
      success: null,
      error: null,
      settings: {
        enabled: false,
        showUnavailable: false,
        showOnlyWithImages: false,
        itemsPerPage: 36,
        analyticsEnabled: false,
        analyticsUseSiteSearch: false,
        analyticsMeasurementId: '',
        popularQueries: {
          enabled: false,
          duration: 30,
          amount: 10,
        },
        recentQueries: {
          enabled: false,
          amount: 10,
        },
      },
      dataLoading: true,
    }
  },
  computed: {
    popularQueriesDurationOptions() {
      return [
        { label: this.$t('search.settings.popularQueries.duration.1'), value: 1 },
        { label: this.$t('search.settings.popularQueries.duration.3'), value: 3 },
        { label: this.$t('search.settings.popularQueries.duration.7'), value: 7 },
        { label: this.$t('search.settings.popularQueries.duration.30'), value: 30 },
        { label: this.$t('search.settings.popularQueries.duration.60'), value: 60 },
        { label: this.$t('search.settings.popularQueries.duration.90'), value: 90 },
      ]
    },
    unsaved() {
      if (!this.loaded || this.originalData === null) {
        return false
      }
      return this.settings.enabled !== this.originalData.enabled
        || this.settings.showUnavailable !== this.originalData.showUnavailable
        || this.settings.showOnlyWithImages !== this.originalData.showOnlyWithImages
        || this.settings.showVariantsOfProduct !== this.originalData.showVariantsOfProduct
        || this.settings.showAllVariantsOfProducts !== this.originalData.showAllVariantsOfProducts
        || this.settings.itemsPerPage !== this.originalData.itemsPerPage
        || this.settings.analyticsEnabled !== this.originalData.analyticsEnabled
        || this.settings.analyticsUseSiteSearch !== this.originalData.analyticsUseSiteSearch
        || this.settings.analyticsMeasurementId !== this.originalData.analyticsMeasurementId
        || this.settings.popularQueries.enabled !== this.originalData.popularQueries.enabled
        || this.settings.popularQueries.duration !== this.originalData.popularQueries.duration
        || this.settings.popularQueries.amount !== this.originalData.popularQueries.amount
        || this.settings.recentQueries.enabled !== this.originalData.recentQueries.enabled
        || this.settings.recentQueries.amount !== this.originalData.recentQueries.amount
    },
  },
  mounted() {
    this.loadSettings()
  },
  methods: {
    loadSettings() {
      this.error = null
      this.success = null
      this.dataLoading = true

      axios.get('/search/settings').then(result => {
        this.originalData = result.data
        this.settings.enabled = result.data.enabled
        this.settings.showUnavailable = result.data.showUnavailable
        this.settings.showOnlyWithImages = result.data.showOnlyWithImages
        this.settings.showVariantsOfProduct = result.data.showVariantsOfProduct
        this.settings.showAllVariantsOfProducts = result.data.showAllVariantsOfProducts
        this.settings.itemsPerPage = result.data.itemsPerPage
        this.settings.analyticsEnabled = result.data.analyticsEnabled
        this.settings.analyticsUseSiteSearch = result.data.analyticsUseSiteSearch
        this.settings.analyticsMeasurementId = result.data.analyticsMeasurementId
        this.settings.popularQueries.enabled = result.data.popularQueries.enabled
        this.settings.popularQueries.duration = parseInt(result.data.popularQueries.duration)
        this.settings.popularQueries.amount = parseInt(result.data.popularQueries.amount)
        this.settings.recentQueries.enabled = result.data.recentQueries.enabled
        this.settings.recentQueries.amount = parseInt(result.data.recentQueries.amount)
        this.dataLoading = false
        this.loaded = true
      }).catch(() => {
        this.dataLoading = false
        this.error = this.$t('search.settings.errorGet')
      })
    },
    updateTemplate() {
      this.error = null
      this.success = null

      this.dataLoading = true

      const settings = {
        enabled: this.settings.enabled,
        showUnavailable: this.settings.showUnavailable,
        showOnlyWithImages: this.settings.showOnlyWithImages,
        showVariantsOfProduct: this.settings.showVariantsOfProduct,
        showAllVariantsOfProducts: this.settings.showAllVariantsOfProducts,
        itemsPerPage: this.settings.itemsPerPage,
        analyticsEnabled: this.settings.analyticsEnabled,
        popularQueriesEnabled: this.settings.popularQueries.enabled,
        popularQueriesDuration: this.settings.popularQueries.duration,
        popularQueriesAmount: this.settings.popularQueries.amount,
        recentQueriesEnabled: this.settings.recentQueries.enabled,
        recentQueriesAmount: this.settings.recentQueries.amount,
      }

      if (this.settings.analyticsEnabled) {
        settings.analyticsUseSiteSearch = this.settings.analyticsUseSiteSearch === '1'
        settings.analyticsMeasurementId = this.settings.analyticsMeasurementId
      }

      axios.post('/search/settings', settings).then(() => {
        this.dataLoading = false
        this.success = this.$t('search.settings.success')

        this.originalData.enabled = this.settings.enabled
        this.originalData.showUnavailable = this.settings.showUnavailable
        this.originalData.showOnlyWithImages = this.settings.showOnlyWithImages
        this.originalData.showVariantsOfProduct = this.settings.showVariantsOfProduct
        this.originalData.showAllVariantsOfProducts = this.settings.showAllVariantsOfProducts
        this.originalData.itemsPerPage = this.settings.itemsPerPage
        this.originalData.analyticsEnabled = this.settings.analyticsEnabled
        this.originalData.analyticsUseSiteSearch = this.settings.analyticsUseSiteSearch
        this.originalData.analyticsMeasurementId = this.settings.analyticsMeasurementId

        this.originalData.popularQueries.enabled = this.settings.popularQueries.enabled
        this.originalData.popularQueries.duration = this.settings.popularQueries.duration
        this.originalData.popularQueries.amount = this.settings.popularQueries.amount
        this.originalData.recentQueries.enabled = this.settings.recentQueries.enabled
        this.originalData.recentQueries.amount = this.settings.recentQueries.amount
      }).catch(() => {
        this.dataLoading = false
        this.error = this.$t('search.settings.errorSet')
      })
    },
    hasFeature(featureSymbol) {
      return store.state.user.appActiveUser.features.includes(featureSymbol)
    },
  },
}
</script>

<style>
.vs-table--tbody-table .tr-values:not(.activeEdit):not(.tr-expandedx):not(.hoverFlat):hover{
  transform: translateY(0px) !important;;
}
</style>
