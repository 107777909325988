<template>
  <div class="mt-2 text-left w-100">
    <vs-row>
      <vs-col class="mb-5">
        <vs-switch v-model="settings.popularQueries.enabled" class="inline-flex">
          <template v-slot:on>
            {{ $t('search.settings.popularQueries.on') }}
          </template>
          <template v-slot:off>
            {{ $t('search.settings.popularQueries.off') }}
          </template>
        </vs-switch>
      </vs-col>
    </vs-row>

    <vs-row>
      <label for="popularQueriesDuration">{{ $t('search.settings.popularQueries.durationInfo') }}</label>
    </vs-row>
    <vs-row>
      <vs-select
        id="popularQueriesDuration"
        v-model="settings.popularQueries.duration"
        :options="popularQueriesDurationOptions"
      >
        <vs-select-item
          v-for="(item,index) in popularQueriesDurationOptions"
          :key="index"
          :value="item.value"
          :text="item.label"
        />
      </vs-select>
    </vs-row>

    <vs-row>
      <label>{{ $t('search.settings.popularQueries.amount') }}</label>
    </vs-row>
    <vs-row>
      <vs-input-number v-model="settings.popularQueries.amount" :step="1" :min="1" :max="100" />
    </vs-row>

  </div>
</template>
<script>
export default {
  name: 'PopularQueriesSettings',
  props: {
    popularQueriesDurationOptions: {},
    settings: {},
  },
}
</script>
