<template>
  <div class="mt-2 text-left w-100 d-block">
    <vs-row>
      <vs-col class="mb-5">
        <vs-switch v-model="settings.recentQueries.enabled" class="inline-flex">
          <template v-slot:on>
            {{ $t('search.settings.recentQueries.on') }}
          </template>
          <template v-slot:off>
            {{ $t('search.settings.recentQueries.off') }}
          </template>
        </vs-switch>
      </vs-col>
    </vs-row>

    <vs-row>
      <label>{{ $t('search.settings.recentQueries.amount') }}</label>
    </vs-row>
    <vs-row>
      <vs-input-number v-model="settings.recentQueries.amount" :step="1" :min="1" :max="100" />
    </vs-row>
  </div>
</template>
<script>
export default {
  name: 'RecentQueriesSettings',
  props: {
    hasFeature: {},
    settings: {},
  },
}
</script>
